import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import BotaoSimular from "@/components/BotaoSimular";
import Grid from "@mui/material/Grid2";

export default function ComoSairDoVermelhoEm2025() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_COMO_SAIR_DO_VERMELHO_EM_2025",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);
  return (
    <Container className="sessao-post">
      <Grid
        item
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
          <Grid
            className="logo-blog"
            size={12}
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}>
            <img
              className="logo-blog-img"
              src="/site/blog/blog_como_sair_do_vermelho_em_2025.png"
              alt="logo-blog"
            />
          </Grid>

          <Grid item size={12}>
            <Typography variant="h1" sx={{ mt: 2, p: 1, textAlign: "center" }}>
              Como sair do vermelho em 2025: dicas para manter suas contas em
              dia e organizar suas Finanças
            </Typography>
          </Grid>

          <Grid item size={12}>
            <Typography sx={{ fontSize: 22, fontStyle: "italic" }}>
              Se 2025 é o ano em que você deseja colocar suas finanças em ordem
              e deixar o "vermelho" para trás, você não está sozinho. Manter o
              nome limpo e organizar suas finanças é um passo fundamental para
              conquistar a tão sonhada tranquilidade financeira.
            </Typography>
          </Grid>
          <Grid item size={12}>
            <Typography sx={{ fontSize: 22 }}>
              Com um planejamento eficiente, é possível pagar contas em dia,
              guardar dinheiro e começar a trilhar o caminho para a
              estabilidade. Confira as nossas dicas para sair do vermelho e
              alcançar a liberdade financeira neste novo ano!
            </Typography>
          </Grid>
          <Grid item container spacing={2} className="sessao">
            <ol className="list">
              <li>
                <strong>Faça um diagnóstico completo das suas finanças</strong>
                <br />O primeiro passo para sair do vermelho em 2025 é entender
                claramente a sua situação financeira. Anote todas as suas
                dívidas, com prazos, valores e taxas de juros. Isso ajudará a
                visualizar qual é o montante total devido e qual a prioridade de
                pagamento para evitar que os juros e encargos se acumulem.
              </li>
              <li>
                <strong>Organize suas finanças pessoais</strong> <br /> Com as
                dívidas mapeadas, a próxima etapa é criar um plano de
                organização financeira. Estabeleça um orçamento mensal que
                contemple todas as suas despesas fixas e variáveis. Uma dica
                importante é cortar gastos desnecessários e redirecionar esse
                dinheiro para o pagamento das dívidas.
                <br />
                Se possível, opte por parcelar algumas contas utilizando
                soluções como a <span className="quita">Quita</span>, para
                parcelar boletos ou Pix. Isso pode ajudar a aliviar o impacto
                imediato no seu orçamento, permitindo que você pague as contas
                de forma mais tranquila e sem se apertar.
              </li>
              <li>
                <strong>Defina prioridades para pagar contas</strong> <br />{" "}
                Quando se está no vermelho, é crucial focar no pagamento das
                dívidas mais urgentes, como aquelas com juros altos. Se você tem
                dívidas no cheque especial, considere quitá-las primeiro.
                Organize suas pendências, e à medida que quitar as contas,
                redirecione os valores pagos para outras dívidas, até que todas
                estejam em dia.
              </li>
              <li>
                <strong>Aproveite tecnologias e soluções financeiras</strong>{" "}
                <br /> O mercado financeiro oferece diversas ferramentas para
                ajudá-lo a sair do vermelho. Plataformas como a{" "}
                <span className="quita">Quita</span> oferecem soluções de
                parcelamento flexíveis para ajudar a pagar suas contas em dia,
                sem comprometer sua saúde financeira. Usufruir dessas
                tecnologias pode ser um grande aliado na organização financeira,
                permitindo que você organize melhor seu fluxo de caixa e
                administre suas dívidas com mais eficiência.
              </li>
              <li>
                <strong>Estabeleça metas financeiras para o futuro</strong>{" "}
                <br />
                Por fim, estabeleça metas financeiras de longo prazo. Se o seu
                objetivo é sair do vermelho e manter sua vida financeira
                equilibrada, é importante ter uma visão clara do que você quer
                alcançar. Seja guardar dinheiro para um projeto, fazer um
                investimento ou simplesmente melhorar sua qualidade de vida,
                tenha um plano para o futuro e trabalhe para atingir essas
                metas, passo a passo.
              </li>
            </ol>

            <Grid item size={12}>
              <Typography sx={{ fontSize: 22 }}>
                Sair do vermelho em 2025 exige disciplina, planejamento e
                comprometimento. Ao seguir as dicas para organizar suas
                finanças, pagar suas contas em dia e começar a guardar dinheiro,
                você poderá dar um grande passo em direção à estabilidade
                financeira.
              </Typography>
            </Grid>
            <Grid item size={12}>
              <Typography sx={{ fontSize: 22 }}>
                <strong>Lembre-se:</strong> o sucesso financeiro não acontece da
                noite para o dia, mas com foco e estratégias, é totalmente
                possível recuperar o controle da sua vida financeira. Não deixe
                que as dívidas definam o seu futuro! financeira.
              </Typography>
            </Grid>

            <Grid item size={12}>
              <Typography sx={{ fontSize: 22 }}>
                Gostou das dicas? Continue acompanhando nosso blog para aprender
                mais sobre como cuidar das suas finanças!
              </Typography>
            </Grid>

            <Grid
              item
              size={12}
              align="center"
              className="botao-simulacao-blog">
              <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
