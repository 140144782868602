import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BotaoSimular from "@/components/BotaoSimular";
import "./index.scss";

export default function NovasRegrasDoPix() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_NOVAS_REGRAS_PIX",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          item
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              size={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog-novas-regras-pix.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item size={12}>
              <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
                Pix terá novas regras a partir de novembro
              </Typography>
            </Grid>

            <Grid item size={12}>
              <Typography
                sx={{ fontSize: 22, fontStyle: "italic", fontWeight: "bold" }}
                gutterBottom>
                A medida se aplica a dispositivos ainda desconhecidos pelo
                sistema bancário, sem alterações para aqueles já utilizados para
                transferências via Pix.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  O que vai mudar?
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  O valor das transferências realizadas a partir de um novo
                  dispositivo será limitado a R$ 200. Além disso, o total diário
                  de envios via celulares e computadores não cadastrados nos
                  bancos será restrito a R$ 1.000.
                </Typography>
              </Grid>

              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Quando as novas regras do Pix começam a valer?
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  As novas regras visam aumentar a segurança dos usuários.
                  Segundo o Banco Central, essas mudanças{" "}
                  <strong>entram em vigor a partir de 1º de novembro</strong>.
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Mais segurança para transferências via Pix:
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  O Banco Central acredita que os novos limites ajudarão a
                  prevenir fraudes e golpes.
                </Typography>
              </Grid>

              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Mudanças para as instituições financeiras em relação ao Pix
                  incluem:
                </Typography>
                <ul className="lista">
                  <li>
                    Implementar uma solução de gerenciamento de risco de fraude,
                    baseada nas informações do Banco Central, para identificar
                    transações atípicas ou não compatíveis com o perfil do
                    cliente
                  </li>
                  <li>
                    Disponibilizar, em canais eletrônicos acessíveis aos
                    clientes, orientações sobre como evitar fraudes.
                  </li>
                  <li>
                    Verificar, pelo menos a cada seis meses, se os clientes
                    possuem marcações de fraude na base de dados do Banco
                    Central, oferecendo tratamento diferenciado a esses
                    clientes.
                  </li>
                </ul>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 20, mb: 1 }}>
                  <strong>Fonte:</strong> uol.com.br
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, mb: 1 }}>
                  É importante destacar que, aqui na{" "}
                  <span className="quita">Quita</span>, já adotamos essas boas
                  práticas ao utilizar um sistema antifraude para cada operação
                  realizada.
                </Typography>
              </Grid>
              <Grid item size={12} align="center">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
