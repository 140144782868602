import { useState } from "react";
import { InputLabel, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { cancelSpinner, activeSpinner } from "@/store/reducers/spinner";
import { post } from "@/services/api";
import { useFormik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import BotaoPrincipal from "@/components/BotaoPrincipal";
import Grid from "@mui/material/Grid2";
import * as Yup from "yup";
import "./newsletter.scss";

export default function Newsletter() {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [mensagemSucesso, setMensagemSucesso] = useState(false);
  const [mensagemErro, setMensagemErro] = useState(false);

  const Schema = Yup.object().shape({
    name: Yup.string().required("Nome obrigatório"),
    email: Yup.string().email("E-mail inválido").required("E-mail obrigatório"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        dispatch(activeSpinner());
        values.tokenCaptcha = await executeRecaptcha();
        const result = await post("public/subscribe-newsletter", values);
        dispatch(cancelSpinner());

        if (result.error) {
          setMensagemErro(true);
          setMensagemSucesso(false);
          return;
        }

        setMensagemSucesso(true);
        setMensagemErro(false);
        formik.resetForm();
      } catch (e) {
        console.log(e);
        setMensagemErro(true);
        setMensagemSucesso(false);
        dispatch(cancelSpinner());
      }
    },
  });

  return (
    <Grid container spacing={2} className="secao-newsletter" maxWidth={"450px"}>
      <form onSubmit={formik.handleSubmit}>
        <Grid item size={12}>
          <Typography className="titulo-newsletter">
            Assine nossa newsletter!
          </Typography>
        </Grid>

        <Grid item size={12}>
          <InputLabel className="input-label">Nome:</InputLabel>
          <TextField
            fullWidth
            name="name"
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item size={12}>
          <InputLabel className="input-label">E-mail:</InputLabel>
          <TextField
            fullWidth
            name="email"
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>

        <Grid item xs={12} align="center">
          <BotaoPrincipal type="submit" className="botao-assinatura">
            ASSINAR AGORA
          </BotaoPrincipal>
        </Grid>

        <Grid item size={12}>
          {mensagemErro && (
            <Typography className="erro">
              😭 Ooops... Algo deu errado. Por favor, recarregue a página tente
              novamente.
            </Typography>
          )}

          {mensagemSucesso && (
            <Typography className="sucesso">
              🥳 Pronto! Você se cadastrou na nossa newsletter.
            </Typography>
          )}
        </Grid>
      </form>
    </Grid>
  );
}
