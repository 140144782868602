import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BotaoSimular from "@/components/BotaoSimular";
import "./index.scss";

export default function SelicPorqueAfetaSuasContas() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_O_QUE_É_A_SELIC_E_POR_QUE_ELA_AFETA_A_SUAS_CONTAS_DO_DIA_A_DIA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              size={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog_selic_por_que_ela_afeta_suas_contas_do_dia_a_dia.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid size={12}>
              <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
                O que é a Selic e por que ela afeta suas contas do dia a dia?
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography
                sx={{ fontSize: 22, fontStyle: "italic" }}
                gutterBottom>
                A taxa Selic é um dos principais indicadores da economia
                brasileira e influencia diretamente o bolso de todos os
                brasileiros.
              </Typography>
              <Typography sx={{ fontSize: 22 }}>
                Se você já ouviu falar sobre a Selic mas não entende exatamente
                o que ela é ou como impacta suas finanças, este texto é para
                você! Aqui, vamos explicar <strong>o que é a Selic</strong>, por
                que ela sobe ou desce, e como isso pode afetar sua rotina
                financeira.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  O que é a taxa Selic?{" "}
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 2 }}>
                  A Selic (Sistema Especial de Liquidação e Custódia) é a taxa
                  básica de juros da economia brasileira. Ela é definida pelo
                  Comitê de Política Monetária (Copom) do Banco Central em
                  reuniões regulares realizadas a cada 45 dias. Essa taxa serve
                  como referência para diversas operações financeiras, como
                  empréstimos, financiamentos e investimentos.
                </Typography>

                <Typography sx={{ fontSize: 20 }}>
                  A Selic é também utilizada como uma ferramenta para controlar
                  a inflação. Quando os preços estão subindo muito rápido, o
                  Banco Central aumenta a Selic para reduzir o consumo e
                  estabilizar os preços. Por outro lado, quando a economia
                  precisa de mais estímulo, a Selic é reduzida para incentivar o
                  consumo e o investimento.
                </Typography>
              </Grid>

              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Por que a Selic afeta suas contas?
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  A Selic influencia a economia como um todo, mas o impacto no
                  dia a dia das pessoas acontece principalmente em três áreas:
                </Typography>

                <ol className="list">
                  <li>
                    <strong>Empréstimos e financiamentos</strong>
                    <br />
                    Quando a Selic sobe, os juros cobrados em empréstimos e
                    financiamentos também aumentam. Isso ocorre porque os bancos
                    usam a taxa Selic como base para calcular os juros que
                    cobram dos clientes.
                    <br />
                    <br />
                    Exemplo prático: Se você tem um financiamento de um carro ou
                    imóvel, pode notar que as parcelas ficam mais altas em
                    momentos de Selic elevada, tornando o crédito mais caro.
                  </li>
                  <li>
                    <strong>Investimentos</strong> <br /> A Selic também afeta o
                    rendimento de investimentos em renda fixa, como o Tesouro
                    Direto, CDBs e a poupança. Quando a taxa está alta, esses
                    investimentos oferecem retornos maiores, o que pode ser uma
                    boa notícia para quem está poupando dinheiro.
                  </li>
                  <li>
                    <strong>Consumo e inflação</strong> <br />
                    Uma Selic elevada desestimula o consumo, pois torna o
                    crédito mais caro e reduz a circulação de dinheiro. Isso
                    ajuda a conter a inflação, que é o aumento generalizado dos
                    preços.
                    <br /> <br />
                    Exemplo prático: Produtos e serviços podem ficar mais
                    acessíveis a longo prazo, mas, inicialmente, as famílias
                    podem sentir um aperto financeiro devido ao aumento das
                    taxas de crédito.
                  </li>
                </ol>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Como se preparar em momentos de alta da Selic?
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  A alta da Selic pode ser desafiadora, mas algumas estratégias
                  podem ajudar você a atravessar esse período com mais
                  tranquilidade:
                </Typography>

                <ol className="list">
                  <li>
                    <strong>Reorganize suas dívida</strong>
                    <br />
                    Se você tem dívidas em aberto, como boletos ou
                    financiamentos, considere alternativas para reduzir o
                    impacto dos juros altos. A{" "}
                    <span className="quita">Quita Boletos</span>, por exemplo,
                    permite parcelar suas contas em até 12 vezes no cartão de
                    crédito, ajudando a aliviar o peso no seu orçamento mensal.
                  </li>
                  <li>
                    <strong>Controle seus gasto</strong> <br /> Evite gastos
                    desnecessários e reavalie suas despesas mensais para manter
                    seus pagamentos em dia.
                  </li>
                  <li>
                    <strong>Planeje-se para o longo prazo</strong> <br />
                    Embora o impacto da Selic alta seja imediato, ela é uma
                    medida temporária para estabilizar a economia. Com
                    planejamento, é possível atravessar esse período de forma
                    mais equilibrada
                  </li>
                </ol>
              </Grid>

              <Grid size={12}>
                <Typography sx={{ fontSize: 24, mb: 1 }}>
                  Conte com a <span className="quita">Quita Boletos</span> para
                  organizar suas finanças
                </Typography>
              </Grid>
              <Grid size={12}>
                <Typography sx={{ fontSize: 20, mb: 1 }}>
                  Nos momentos em que a Selic está alta e o crédito mais caro,
                  contar com soluções financeiras flexíveis faz toda a
                  diferença. A <span className="quita">Quita Boletos</span> está
                  aqui para ajudar você a quitar suas contas e reorganizar sua
                  vida financeira de forma rápida e segura. Com a possibilidade
                  de parcelamento em até 12x no cartão de crédito, você pode
                  respirar mais tranquilo enquanto se ajusta ao cenário
                  econômico atual.
                </Typography>
              </Grid>
              <Grid size={12} align="center">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
