const blogPosts = [
  {
    image:
      "/site/blog/blog_selic_por_que_ela_afeta_suas_contas_do_dia_a_dia.png",
    titleImg: "O que é a Selic e por que ela afeta suas contas do dia a dia?",
    title: "O que é a Selic e por que ela afeta suas contas do dia a dia?",
    description: `Com as novas mudanças na taxa Selic, é importante entender como ela afeta suas finanças. Confira neste artigo!`,
    url: "selic_por_que_afeta_suas_contas",
  },
  {
    image: "/site/blog/blog_novas_regras_para_o_pix.png",
    titleImg:
      "Novas regras para o Pix: O que você precisa saber e como isso afeta suas finanças",
    title:
      "Novas regras para o Pix: O que você precisa saber e como isso afeta suas finanças",
    description: `O Pix é uma das formas de pagamento mais populares do Brasil. Mas você sabe quais são as novas regras para o Pix? Confira neste artigo!`,
    url: "novas_regras_para_o_pix",
  },
  {
    image: "/site/blog/blog_como_sair_do_vermelho_em_2025.png",
    titleImg:
      "Como sair do vermelho em 2025: dicas para manter suas contas em dia e organizar suas Finanças",
    title:
      "Como sair do vermelho em 2025: dicas para manter suas contas em dia e organizar suas Finanças",
    description: `Ano novo, vida financeira nova! Neste artigo, separamos algumas dicas para ajudar você a sair do vermelho em 2025 e manter suas contas em dia. Confira!`,
    url: "como_sair_do_vermelho_em_2025",
  },
  {
    image:
      "/site/blog/blog_dicas_praticas_para_evitar_dividas_e_manter_o_equilibrio_financeiro.png",
    titleImg:
      "Organize suas finanças: dicas práticas para evitar dívidas e manter o equilíbrio financeiro",
    title:
      "Organize suas finanças: dicas práticas para evitar dívidas e manter o equilíbrio financeiro",
    description: `Manter as contas em dia e evitar dívidas é um desafio para muitos brasileiros. Neste artigo, separamos algumas dicas práticas para ajudar você a organizar suas finanças e manter o equilíbrio financeiro. Confira!`,
    url: "dicas_praticas_para_evitar_dividas_e_manter_o_equilibrio_financeiro",
  },
  {
    image:
      "/site/blog/blog_black_friday_como_evitar_sites_fraudulentos_e_golpes.png",
    titleImg: "Black Friday: Como evitar sites fraudulentos e compras golpe",
    title: "Black Friday: Como evitar sites fraudulentos e compras golpe",
    description: `Um dos momentos mais aguardados do ano para realizar as suas compras está chegando, a Black Friday. Mas, é preciso ficar atento para não cair em golpes e sites fraudulentos. Confira as dicas que separamos para você!`,
    url: "black_friday_como_evitar_sites_fraudulentos_e_golpes",
  },
  {
    image: "/site/blog/blog-pix-com-cartao-de-credito-a-solucao-ideal.png",
    titleImg:
      "PIX com Cartão de Crédito: A solução ideal para parcelar pagamentos",
    title:
      "PIX com Cartão de Crédito: A solução ideal para parcelar pagamentos",
    description: `Vamos explorar como você pode usar o PIX com o cartão de crédito e resolver alguns dos problemas mais comuns na hora de pagar suas contas.`,
    url: "pix_com_cartao_de_credito_a_solucao_ideal",
  },
  {
    image: "/site/blog/blog-novas-regras-pix.png",
    titleImg: "Pix terá novas regras",
    title: "Pix terá novas regras",
    description: `A partir de novembro, o Pix terá novas regras. Entenda o que muda e como isso pode impactar o seu dia a dia financeiro.`,
    url: "novas-regras-pix",
  },
  {
    image: "/site/blog/blog-desvantagens-uso-cheque-especial.png",
    titleImg: "Desvantagens do uso do cheque especial e outras alternativas",
    title: "Desvantagens do uso do cheque especial e outras alternativas",
    description: `Veja neste artigo que montamos as desvantagens de utilizar o cheque especial e outras alternativas para quando o orçamento aperta. Confira!`,
    url: "desvantagens-uso-cheque-especial",
  },
  {
    image: "/site/blog/blog-quer-saber-como-evitar-o-endividamento.png",
    titleImg: "Quer saber como evitar o endividamento? ",
    title: "Quer saber como evitar o endividamento? ",
    description: `Entenda por que parcelar suas contas pode ajudar a organizar suas finanças.`,
    url: "quer-saber-como-evitar-o-endividamento",
  },
  {
    image: "/site/blog/blog-nosso-futoro-comeca-agora.png",
    titleImg: "Nosso futuro começa agora",
    title: "Nosso futuro começa agora",
    description: `Estamos de cara nova e ainda mais focados em trazer otimizações e soluções financeiras que ajudem os brasileiros a viver o presente e ter uma vida financeira mais tranquila.`,
    url: "nosso-futuro-comeca-agora",
  },
  {
    image: "/site/blog/blog-nos-te-convidamos-a-desacelerar.png",
    titleImg: "Em uma rotina tão corrida, nós te convidamos a desacelerar",
    title: "Em uma rotina tão corrida, nós te convidamos a desacelerar",
    description: `Parece que estamos sempre correndo contra o tempo. O ritmo acelerado da vida moderna exige de nós cada vez mais. No meio de tanta correria, esquecemos de cuidar de algo essencial: nossa tranquilidade financeira.`,
    url: "nos-te-convidamos-a-desacelerar",
  },
  {
    image: "/site/blog/blog-liberdade-para-realizar-pix-sem-saldo.png",
    titleImg: "A liberdade para realizar pix sem saldo na conta",
    title: "A liberdade para realizar pix sem saldo na conta",
    description: `Nos últimos anos, o PIX revolucionou a forma como fazemos pagamentos no Brasil. Mas e quando não temos saldo suficiente na conta? Neste artigo iremos te mostrar a liberdade de realizar pagamentos sem saldo na conta.`,
    url: "liberdade-para-realizar-pix-sem-saldo-na-conta",
  },
  {
    image: "/site/blog/blog-como-organizar-minhas-financas-pessoais.png",
    titleImg: "Como posso começar a organizar minhas finanças pessoais?",
    title: "Como posso começar a organizar minhas finanças pessoais?",
    description: `Organizar suas finanças pessoais é um passo crucial para alcançar a estabilidade e a tranquilidade financeira. Venha conosco e vamos explorar estratégias práticas e eficientes para gerenciar seu dinheiro.`,
    url: "como-organizar-as-finanacas-pessoais",
  },
  {
    image:
      "/site/blog/blog_organizacao_financeira_como_manter_seus_pagamentos_em_dia.png",
    titleImg: "Organização financeira: como manter seus pagamentos em dia",
    title: "Organização financeira: como manter seus pagamentos em dia",
    description: `Em um mundo onde a gestão financeira pode ser um verdadeiro desafio, encontrar soluções que realmente facilitam a vida é essencial. Neste artigo iremos mostrar como manter seus pagamentos em dia.`,
    url: "como-manter-seus-pagamentos-em-dia",
  },
  {
    image: "/site/blog/blog_5_habitos_de_pessoas_bem_sucedidas.png",
    titleImg: "5 Hábitos de pessoas bem-sucedidas",
    title: "5 Hábitos de pessoas bem-sucedidas",
    description: `Separamos cinco hábitos essenciais que podem impulsionar seu desenvolvimento e crescimento financeiro e profissional. Confira!`,
    url: "habitos-de-pessoas-bem-sucedidas",
  },
  {
    image: "/site/blog/blog_5_dicas_para_manter_as_contas_em_dia.png",
    titleImg: "5 dicas infalíveis para manter suas contas em dia",
    title: "5 dicas infalíveis para manter suas contas em dia",
    description: `Manter as contas em dia é essencial para garantir tranquilidade financeira e evitar o acúmulo de dívidas. Para ajudá-lo nessa tarefa, separamos algumas dicas is que irão facilitar o gerenciamento do seu orçamento. Confira!`,
    url: "dicas-para-manter-as-contas-em-dia",
  },
  {
    image: "/site/blog/blog_parcelamento_de_contas_tudo_que_precisa_saber.png",
    titleImg: "Parcelamento de contas: tudo que voce precisa saber",
    title: "Parcelamento de contas: tudo que voce precisa saber",
    description: `O parcelamento de contas tornou-se uma ferramenta essencial para muitos brasileiros, acompanhe neste blog tudo que precisa saber e os benefícios que o parcelamento de contas traz para o seu planejamento financeiro.`,
    url: "tudo-que-precisa-saber-sobre-parcelamento-de-contas",
  },
  {
    image: "/site/blog/blog-parcelamento-de-pix-no-credito.png",
    titleImg: "Parcelamento de PIX no crédito",
    title: "Parcelamento de PIX no crédito",
    description: `O parcelamento de PIX pode ser uma solução para quem busca mais flexibilidade nas contas. Separe 5 minutos para entender melhor como funciona e quais as vantagens que trazemos neste artigo.`,
    url: "parcelamento-pix-credito",
  },
  {
    image: "/site/blog/blog-parcelar-iptu-e-ipva.png",
    titleImg: "Entenda como parcelar seu IPTU e IPVA no cartão de crédito",
    title: "Entenda como parcelar seu IPTU e IPVA no cartão de crédito",
    description: `Parcelar IPTU e IPVA pode ser a melhor solução para quem não possui todo o valor necessário de imediato ou deseja mais flexibilidade no pagamento.`,
    url: "parcelar-iptu-e-ipva-no-cartao",
  },
  {
    image: "/site/blog/blog-como-pagar-boleto-vencido.png",
    titleImg: "Como pagar boleto vencido",
    title: "Como pagar boleto vencido",
    description: `Quem nunca se viu em apuros ao perceber que esqueceu de pagar um boleto até a data de vencimento? Venha conosco nesta leitura e descubra a facilidade que é utilizar a plataforma da Quita Boletos para os momentos de apuros.`,
    url: "como-pagar-boleto-vencido",
  },
  {
    image: "/site/blog/blog-parcelamento-atraves-do-cartao-de-credito.png",
    titleImg: "Parcelar contas através do cartão de crédito",
    title: "Parcelar contas através do cartão de crédito ",
    description:
      "Uma conta não paga rapidamente pode virar uma divida e uma grande dor de cabeça. Neste artigo iremos lhe mostrar como parcelar contas através do cartão de crédito pode ser uma solução para a sua reorganização financeira.",
    url: "parcelar-atraves-do-cartao-de-credito",
  },
  {
    image: "/site/blog/blog_parcelamento_de_pix.png",
    titleImg: "Tudo que você precisa saber sobre o parcelamento de PIX",
    title: "Tudo que você precisa saber sobre o parcelamento de PIX",
    description:
      "Em momentos de aperto financeiro, ter a possibilidade de parcelar um pagamento pode fazer toda a diferença. Separe 5 minutos para a leitura deste artigo que lhe explicaremos tudo que você precisa saber sobre o parcelamento de PIX.",
    url: "parcelamento-de-pix",
  },
  {
    image: "/site/blog/blog_parcelamento_de_contas.png",
    titleImg:
      "Parcelamento de Contas: Uma estratégia inteligente para reorganizar suas finanças",
    title:
      "Parcelamento de Contas: Uma estratégia inteligente para reorganizar suas finanças",
    description:
      "Encontrar maneiras de equilibrar o orçamento e manter as contas em dia pode ser um verdadeiro desafio. Iremos te mostrar como o parcelamento de contas pode ser uma estratégia inteligente para reorganizar suas finanças.",
    url: "parcelamento-de-contas",
  },
  {
    image: "/site/blog/blog-guia-para-manter-as-financas-em-ordem.png",
    titleImg: "Guia para manter suas finanças em ordem",
    title: "Guia para manter suas finanças em ordem",
    description:
      "Aprenda neste artigo como manter suas finanças sob controle e enfrentar os desafios que podem surgir ao longo do caminho.",
    url: "guia-para-manter-as-financas-em-ordem",
  },
  {
    image: "/site/blog/blog-mas-afinal-como-funciona.png",
    titleImg: "Mas afinal, como funciona?",
    title: "Mas afinal, como funciona?",
    description:
      "Ainda tens dúvidas de como contratar os serviços da Quita Boletos? Neste artigo iremos lhe mostrar um passo a passo de como realizar a contratação.",
    url: "como-funciona",
  },
  {
    image: "/site/blog/blog-liberte-se-da-inadimplencia.png",
    titleImg:
      "Liberte-se da inadimplência e conquiste sua liberdade financeira",
    title: "Liberte-se da inadimplência e conquiste sua liberdade financeira",
    description:
      "Viver endividado pode ser uma fonte constante de estresse e ansiedade. Neste blog, iremos lhe mostrar como que as soluções da Quita Boletos é possível dar um novo rumo para suas finanças.",
    url: "liberte-se-da-inadimplencia",
  },
  {
    image: "/site/blog/blog-menos-dividas-mais-esperanca.png",
    titleImg: "Brasil 2023: Menos dívidas, mais espeçanças",
    title: "Brasil 2023: Menos dívidas, mais esperanças",
    description:
      "2023 está chegando ao fim e iremos explorar o desejo de progresso da população referente às suas dívidas e traremos dicas de como é possível, ainda este ano, livrar-se de muitos boletos incômodos",
    url: "menos-dividas-mais-esperanca",
  },
  {
    image: "/site/blog/blog-psicologico.jpg",
    titleImg: "O endividamento no Brasil e o impacto psicológico causado.",
    title: "O endividamento no Brasil e o impacto psicológico causado.",
    description:
      "O endividamento é uma realidade que afeta milhões de brasileiros, e os impactos psicológicos dessa situação podem ser profundos.",
    url: "endividamento-impacta-psicologico",
  },
  {
    image: "/site/blog/logo-os-benefícios-do-parcelamento-de-contas.jpg",
    titleImg: "Os benefícios do parcelamento de contas.",
    title: "Os benefícios do parcelamento de contas.",
    description:
      "O dilema cotidiano ainda é “devo parcelar minhas dívidas ou pagar à vista?”. Gaste apenas 5 minutos do seu tempo e descubra em  4 tópicos os benefícios de parcelar seus boletos.",
    url: "os-beneficios-do-parcelamento-de-contas",
  },
  {
    image: "/site/logo-como-parcelar-meus-boletos.png",
    titleImg: "Como parcelar meus boletos?",
    title: "Como parcelar meus boletos?",
    description:
      "Quando as contas começam a se acumular e o orçamento aperta, o que fazer? Neste post iremos mostrar as facilidades que o parcelamento dos seus boletos pode trazer para o seu orçamento!",
    url: "como-parcelar-meus-boletos",
  },
  {
    image: "/site/blog-fgts.png",
    titleImg: "FGTS: fazer ou não o adiantamento?",
    title: "FGTS: fazer ou não o adiantamento?",
    description:
      "Com a liberação do FGTS, surge uma oportunidade única de ter um dinheiro extra, mas que deve ser usado e administrado com consciência e inteligência financeira. Por isso, preparamos uma leitura de 5 minutos para lhe ajudar.",
    url: "fgts-fazer-adiantamento-ou-nao",
  },
  {
    image: "/site/blog/blog-aviao.png",
    titleImg: "Segredo sobre pagar boletos com cartão de crédito.",
    title: "Segredo sobre pagar boletos com cartão de crédito.",
    description:
      "Já faz algum tempo que o pagamento de contas através de cartão de crédito chegou para a felicidade geral da nação. Clique aqui e saiba mais sobre.",
    url: "segredos-que-ninguem-te-contou",
  },
  {
    image: "/site/blog-5-beneficios.png",
    titleImg: "5 Benefícios de parcelar seus boletos",
    title: "5 Benefícios de parcelar seus boletos",
    description:
      "Fazer compras e pagar contas é uma parte inevitável da vida adulta. Entenda melhor neste post.",
    url: "cinco-beneficios-de-parcelar-boletos",
  },
];
export default blogPosts;
