import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import BotaoSimular from "@/components/BotaoSimular";
import Grid from "@mui/material/Grid2";

export default function NovasRegrasParaPix() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_NOVAS_REGRAS_PARA_PIX",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <Container className="sessao-post">
      <Grid
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
          <Grid
            className="logo-blog"
            size={12}
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}>
            <img
              className="logo-blog-img"
              src="/site/blog/blog_novas_regras_para_o_pix.png"
              alt="logo-blog"
            />
          </Grid>

          <Grid size={12}>
            <Typography variant="h1" sx={{ mt: 2, p: 1, textAlign: "center" }}>
              Novas regras para o Pix: O que você precisa saber e como isso
              afeta suas finanças
            </Typography>
          </Grid>

          <Grid size={12}>
            <Typography sx={{ fontSize: 22, fontStyle: "italic" }}>
              As novas regras do Pix, implementadas pela Receita Federal, visam
              aumentar a transparência nas transações e combater a sonegação
              fiscal. Se você costuma utilizar o Pix para pagamentos, é
              importante entender como essas alterações podem impactar sua
              rotina financeira. Neste texto, explicamos as novas regras e como
              elas afetam suas transações.
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography sx={{ fontSize: 22 }}>
              O <strong>Pix</strong> passou a ser monitorado de forma mais
              rigorosa com a implementação de novas regras pela{" "}
              <strong>Receita Federal</strong>. Essas mudanças visam aumentar a
              transparência nas movimentações financeiras e combater a evasão
              fiscal. Embora não envolvam novos impostos, elas afetam tanto
              pessoas físicas quanto jurídicas e trazem impactos diretos no dia
              a dia dos brasileiros.
            </Typography>
          </Grid>
          <Grid container spacing={2} className="sessao">
            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 2 }}>
                O que muda no Pix?
              </Typography>
              <Typography sx={{ fontSize: 22, mb: 2 }}>
                A principal mudança é o monitoramento de transações que
                ultrapassam certos limites mensais. A{" "}
                <strong>Receita Federal</strong> agora exige que os bancos e
                fintechs reportem operações de <strong>Pix</strong> realizadas
                por pessoas físicas que superem R$ 5.000 por mês e por pessoas
                jurídicas que excedam R$ 15.000 por mês. Este movimento tem como
                objetivo garantir maior transparência e ajudar no combate à
                sonegação fiscal.
              </Typography>
              <Typography sx={{ fontSize: 22 }}>
                Essas transações serão compartilhadas com a Receita Federal sem
                violar o sigilo bancário, e os dados serão usados para fins
                fiscais, como a declaração de Imposto de Renda. Isso facilitará
                o preenchimento das declarações e, ao mesmo tempo, ajudará a
                Receita a identificar possíveis inconsistências fiscais.
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 2 }}>
                Quais os benefícios dessas mudanças?
              </Typography>
              <Typography sx={{ fontSize: 22 }}>
                Apesar de a medida gerar algumas preocupações sobre privacidade,
                o principal benefício dessas novas regras é o aumento da
                segurança nas transações financeiras e o combate à fraude
                financeira. Além disso, a declaração de Imposto de Renda se
                tornará mais simplificada, pois as informações sobre os valores
                transacionados estarão automaticamente disponíveis, evitando o
                erro humano e acelerando o processo.
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 2 }}>
                Entenda a regulação e como isso impacta a sua rotina?
              </Typography>
              <Typography sx={{ fontSize: 22, mb: 2 }}>
                Essas mudanças não trazem novos impostos para quem faz uso do
                Pix, mas ampliam a vigilância sobre os valores movimentados.
                Isso significa que, mesmo que você não seja um contribuinte com
                altos valores, ainda assim, precisa ficar atento ao
                monitoramento das transações. Como a{" "}
                <strong>Receita Federal</strong>
                foca nas transações de alto volume, as pessoas que realizam
                pagamentos maiores precisam garantir que tudo esteja de acordo
                com a lei, para evitar contratempos fiscais no futuro.
              </Typography>
              <Typography sx={{ fontSize: 22 }}>
                Essas alterações podem parecer um pouco desafiadoras para quem
                está acostumado com a liberdade de transações rápidas do
                <strong>Pix</strong>. Porém, ao mesmo tempo, essas novas regras
                garantem mais segurança e controle tanto para os usuários quanto
                para as instituições financeiras.
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 2 }}>
                Quais os cuidados você deve ter?
              </Typography>
              <Typography sx={{ fontSize: 22, mb: 2 }}>
                Com as novas regras em vigor, é fundamental que os usuários do{" "}
                <strong>Pix</strong>
                fiquem atentos às transações realizadas. Se você fizer
                movimentações que ultrapassem os limites de{" "}
                <strong>R$ 5.000 (pessoas físicas)</strong> ou{" "}
                <strong>R$ 15.000 (pessoas jurídicas)</strong> por mês, será
                necessário garantir que tudo esteja em conformidade com a
                legislação para evitar problemas com a{" "}
                <strong>Receita Federal</strong>.
              </Typography>
              <Typography sx={{ fontSize: 22 }}>
                Isso significa, por exemplo, que você deve manter um registro
                detalhado de suas transações e estar preparado para prestar
                esclarecimentos, caso solicitado. Além disso, a{" "}
                <strong>Receita Federal</strong> usará essas informações para
                cruzar dados e facilitar a declaração de Imposto de Renda,
                tornando o processo mais ágil, mas também mais vigilante.
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 2 }}>
                Alternativa para emergências financeiras: Como a{" "}
                <span className="quita">Quita Boletos</span> pode ajudar
              </Typography>
              <Typography sx={{ fontSize: 22 }}>
                Enquanto as novas regras do <strong>Pix</strong> tornam as
                transações financeiras mais transparentes, sabemos que a vida
                financeira pode, às vezes, ser imprevisível. Se você se
                encontrar em uma emergência financeira, a{" "}
                <span className="quita">Quita Boletos</span> é uma solução
                eficaz para quem precisa pagar contas e não tem o saldo
                disponível na conta. Através do parcelamento via cartão de
                crédito, você pode quitar seus boletos com mais flexibilidade e
                ganhar tempo para se organizar financeiramente. Com a{" "}
                <span className="quita">Quita Boletos</span>, você tem a
                agilidade necessária para enfrentar imprevistos sem comprometer
                seu orçamento
              </Typography>
            </Grid>

            <Grid size={12}>
              <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                Fontes:
              </Typography>
              <Typography sx={{ fontSize: 22 }}>
                Agência Brasil <br />
                Jota.info <br />
                Infomoney <br />
                Einvestidor
              </Typography>
            </Grid>

            <Grid size={12} align="center" className="botao-simulacao-blog">
              <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
