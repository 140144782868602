import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function NosTeConvidamosADesacelerar() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_NÓS_TE_CONVIDAMOS_A_DESACELERAR",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post" justifyContent="center">
        <Grid container justifyContent="center">
          <Grid
            size={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="md"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                size={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog-img"
                  src="/site/blog/blog-nos-te-convidamos-a-desacelerar.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid item size={12} sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Em uma rotina tão corrida, nós te convidamos a desacelerar
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Nos dias de hoje, parece que estamos sempre correndo contra
                    o tempo. O ritmo acelerado da vida moderna exige de nós cada
                    vez mais, e a pressão para dar conta de todas as
                    responsabilidades pode ser avassaladora. No meio de tanta
                    correria, esquecemos de cuidar de algo essencial:{" "}
                    <strong> nossa tranquilidade financeira.</strong>
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    A boa notícia é que desacelerar é possível, e nós da{" "}
                    <span className="quita">Quita Boletos</span> estamos aqui
                    para te ajudar nesse processo. Entendemos que a relação dos
                    brasileiros com as finanças muitas vezes é marcada pela
                    ansiedade e pelo peso das obrigações. Por isso, pensamos em
                    soluções que simplificam o seu dia a dia, proporcionando
                    mais tempo e paz de espírito.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Estamos <strong>lançando uma NOVA MARCA</strong>, focada em
                    transformar a maneira como você lida com suas finanças.
                    Nosso objetivo é descomplicar o que parece complicado,
                    trazendo mais leveza para sua rotina. Com transparência,
                    proteção de dados e total visibilidade em cada etapa do
                    processo, a nova <span className="quita">Quita</span> quer
                    ser a parceira que você precisa para enfrentar os desafios
                    financeiros com mais leveza.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Oferecemos soluções práticas, acessíveis e seguras que se
                    adaptam ao seu estilo de vida, permitindo que você viva o
                    presente. Sabemos que, quando se trata de finanças, a
                    confiança é fundamental. Por isso, nossa proposta é clara:{" "}
                    <strong>
                      queremos que você se sinta seguro e encare os desafios com
                      menos burocracia e mais flexibilidade.
                    </strong>
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Então, se a sua rotina está te deixando sem fôlego, aceite
                    nosso convite para desacelerar. Para que você possa se
                    concentrar no que realmente importa:{" "}
                    <strong>viver com mais tranquilidade.</strong>
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                size={12}
                align="center"
                className="botao-simulacao-blog">
                <BotaoSimular>
                  FAÇA UMA SIMULAÇÃO E COMEÇE A DESACELERAR
                </BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
