import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import BotaoSimular from "@/components/BotaoSimular";

export default function ComoPossoOrganizarAsFinancasPessoais() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_COMO_POSSO_ORGANIZAR_AS_MINHAS_FINANCAS_PESSOAIS",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post" justifyContent="center">
        <Grid container justifyContent="center">
          <Grid
            size={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="md"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                size={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  className="logo-blog-img"
                  src="/site/blog/blog-como-organizar-minhas-financas-pessoais.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid item size={12} sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Como posso começar a organizar minhas finanças pessoais?
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item size={12}>
                  <Typography variant="h3" sx={{ fontStyle: "italic", mt: 2 }}>
                    Organizar suas finanças pessoais é um passo crucial para
                    alcançar a estabilidade e a tranquilidade financeira. A
                    seguir, vamos explorar estratégias práticas e eficientes
                    para gerenciar seu dinheiro, utilizando conceitos
                    importantes do livro "Psicologia Financeira" de Morgan
                    Housel.
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Este guia ajudará você a criar um planejamento financeiro
                    sólido e sustentável, abordando temas como organização
                    financeira, segurança financeira, planejamento mensal,
                    investimento, reserva financeira e reparcelamento de
                    dívidas.
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold", my: 2 }}>
                    Confira como organizar suas finanças pessoais:
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    1. Organização Financeira
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    O primeiro passo para uma vida financeira saudável é a
                    organização. Comece registrando todas as suas receitas e
                    despesas. Utilize ferramentas como planilhas, aplicativos de
                    controle financeiro ou até mesmo um caderno para anotar
                    tudo. Este processo permitirá que você visualize para onde
                    seu dinheiro está indo e identifique áreas onde pode
                    economizar.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    2. Segurança Financeira
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    A segurança financeira é fundamental para uma vida
                    tranquila. Segundo Morgan Housel, ter uma reserva financeira
                    é essencial para lidar com imprevistos sem comprometer seu
                    orçamento mensal. Recomenda-se que essa reserva seja
                    suficiente para cobrir de três a seis meses de despesas
                    essenciais.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    3. Planejamento Mensal
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Criar um planejamento mensal é determinante para manter suas
                    finanças sob controle. Estabeleça um orçamento que contemple
                    todas as suas despesas fixas e variáveis, e defina metas de
                    economia e investimento. A disciplina nesse processo é
                    crucial para evitar endividamentos e garantir que suas
                    finanças estejam sempre equilibradas.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    4. Investimento
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Investir é uma das melhores maneiras de fazer seu dinheiro
                    trabalhar para você. Identifique opções de investimento que
                    se adequem ao seu perfil de risco e aos seus objetivos
                    financeiros.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    5. Reserva Financeira
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Investir é uma das melhores maneiras de fazer seu dinheiro
                    trabalhar para você. Identifique opções de investimento que
                    se adequem ao seu perfil de risco e aos seus objetivos
                    financeiros.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    6. Reparcelamento de Dívidas
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Se você já possui dívidas, considere a opção de
                    reparcelamento para aliviar o peso das parcelas mensais e
                    ganhar mais flexibilidade para gerenciar seu orçamento.
                    Reparcelar pode proporcionar taxas de juros mais baixas e
                    prazos maiores, facilitando o pagamento e evitando a
                    inadimplência.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                    A importância da educação e tranquilidade financeira
                  </Typography>
                </Grid>

                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    A educação financeira é uma ferramenta poderosa para tomar
                    decisões conscientes e inteligentes sobre seu dinheiro.
                    Quanto mais você entender sobre finanças pessoais, melhor
                    será sua capacidade de gerenciar seu patrimônio e garantir
                    uma vida financeira saudável.
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Além disso, a tranquilidade financeira é essencial para a
                    saúde mental e a qualidade de vida. Ter suas finanças em
                    ordem proporciona conforto e bem-estar, permitindo que você
                    se concentre em outras áreas importantes da sua vida sem a
                    preocupação constante com o dinheiro.
                  </Typography>
                </Grid>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Como Morgan Housel afirma:
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2, fontWeight: "bold" }}>
                    "O maior controle que você tem sobre sua vida financeira é o
                    controle emocional."
                  </Typography>
                </Grid>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Organizar suas finanças pessoais pode parecer desafiador no
                    início, mas com as estratégias certas e uma mentalidade
                    focada, é possível alcançar a estabilidade financeira.
                    Lembre-se de que o equilíbrio é a chave: ao mesmo tempo que
                    você economiza e investe, é importante também cuidar da sua
                    qualidade de vida.
                  </Typography>
                </Grid>
                <Grid item size={12}>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    Referência:
                  </Typography>
                  <Typography sx={{ fontSize: 20, my: 2 }}>
                    HOUSSEL, Morgan. Psicologia Financeira: Lições atemporais
                    sobre fortuna, ganância e felicidade.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                size={12}
                align="center"
                className="botao-simulacao-blog">
                <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
