import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import BotaoSimular from "@/components/BotaoSimular";
import Grid from "@mui/material/Grid2";
import "./index.scss";

export default function QuerSaberComoEvitarOEndividamento() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_QUER_SABER_COMO_EVITAR_O_ENDIVIDAMENTO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-post">
        <Grid
          item
          size={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              size={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                className="logo-blog-img"
                src="/site/blog/blog-quer-saber-como-evitar-o-endividamento.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item size={12}>
              <Typography variant="h1" sx={{ mt: 2, p: 1 }}>
                Quer saber como evitar o endividamento? Entenda por que parcelar
                suas contas pode ajudar a organizar suas finanças
              </Typography>
            </Grid>

            <Grid item size={12}>
              <Typography sx={{ fontSize: 22 }}>
                Manter as contas em dia nem sempre é uma tarefa fácil. Muitas
                pessoas enfrentam dificuldades para pagar todos os boletos no
                prazo, o que pode levar a endividamento e juros acumulados.
                Nesse cenário, parcelar suas contas pode ser uma solução
                interessante para evitar problemas financeiros e trazer mais
                organização ao orçamento.
              </Typography>
            </Grid>

            <Grid container spacing={2} sx={{ mx: 1, mt: 2 }}>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  O impacto dos juros de atraso
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Quando um boleto não é pago na data de vencimento, é comum que
                  a pessoa tenha que lidar com juros e multas. Esse aumento no
                  valor da dívida pode tornar o pagamento ainda mais difícil nos
                  meses seguintes, criando uma bola de neve financeira.
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Parcelar boletos pode ser uma alternativa para aliviar o peso
                  desses valores extras. Ao dividir o pagamento, você consegue
                  encaixar o boleto no orçamento mensal de forma mais acessível,
                  evitando que a dívida cresça descontroladamente.
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Como o parcelamento pode ajudar no planejamento financeiro
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Parcelar boletos permite que você ajuste suas finanças de
                  maneira mais eficiente. Em vez de comprometer uma grande parte
                  do salário ou da renda com um único pagamento, o valor é
                  distribuído em várias parcelas. Isso pode ajudar a manter o
                  equilíbrio financeiro, evitando que outras contas fiquem em
                  atraso.
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Além disso, o parcelamento oferece maior previsibilidade sobre
                  as despesas mensais, o que facilita o controle sobre os
                  gastos. Saber quanto será destinado ao pagamento de boletos em
                  cada mês é essencial para evitar surpresas no fim do mês.
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Dicas práticas para organizar suas finanças
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Se você está pensando em parcelar seus boletos para organizar
                  sua vida financeira, aqui estão algumas dicas que podem
                  ajudar:
                </Typography>

                <ol className="lista-organizacao">
                  <li>
                    Faça uma lista de todas as suas despesas fixas e variáveis.
                  </li>
                  <li>
                    Priorize os pagamentos mais urgentes, como contas de consumo
                    (água, luz, internet).
                  </li>
                  <li>
                    Verifique os boleto que podem ser parcelados e calcule como
                    isso impacta o seu orçamento.
                  </li>
                  <li>
                    Crie um planejamento mensal, definindo um valor máximo para
                    destinar ao pagamento de contas e dívidas.
                  </li>
                </ol>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Quando parcelar suas contas é uma boa opção?
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  O parcelamento pode ser uma solução inteligente em várias
                  situações, como em momentos de imprevistos, como um gasto
                  inesperado com saúde ou manutenção do carro. Também é útil
                  quando o pagamento de uma conta compromete o orçamento de
                  outras despesas importantes.
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Vale lembrar que parcelar com o cartão de crédito é uma
                  alternativa segura, ideal para ajudar a atravessar momentos de
                  aperto financeiro, mas é importante manter o controle e buscar
                  equilibrar o orçamento a longo prazo.
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Como a <span className="quita">Quita Boletos</span> pode te
                  ajudar?
                </Typography>
                <Typography sx={{ fontSize: 20 }}>
                  Com a <span className="quita">Quita Boletos</span>, parcelar
                  seus boletos é fácil, rápido e sem burocracia. O processo é
                  100% online, permitindo que você faça a simulação de pagamento
                  em até 12 vezes. Além disso, nossa equipe está disponível para
                  te oferecer o suporte necessário, garantindo uma experiência
                  prática e segura.
                </Typography>
                <Typography sx={{ fontSize: 20, mt: 2 }}>
                  Parcelar boletos é uma estratégia que pode ajudar a evitar
                  endividamentos e garantir que as contas fiquem em dia. Com a{" "}
                  <span className="quita">Quita Boletos</span>, essa solução é
                  acessível, descomplicada e eficiente, permitindo que você
                  mantenha suas finanças organizadas e tenha mais tranquilidade
                  no dia a dia.
                </Typography>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 24, fontWeight: "bold", mb: 1 }}>
                  Perguntas Frequentes:
                </Typography>
                <ul className="lista-organizacao">
                  <li className="item-lista">
                    Como funciona o parcelamento de boletos? <br />
                    Nós liquidamos seus boletos ou PIX à vista, enquanto você
                    parcela em até 12 vezes no cartão de crédito.
                  </li>
                  <li className="item-lista">
                    É possível parcelar boletos de qualquer valor? <br /> <br />
                    O valor máximo para contratação é de R$ 10.000,00. Se
                    precisar de um valor maior, você pode realizar mais de uma
                    operação ou entrar em contato conosco para assistência.
                  </li>
                  <li className="item-lista">
                    Quais são as vantagens de usar a{" "}
                    <span className="quita">Quita Boletos</span> para parcelar
                    meus boletos? <br /> <br />
                    As principais vantagens de usar a{" "}
                    <span className="quita">Quita Boletos</span> para parcelar
                    suas contas incluem:
                    <ul className="lista-organizacao">
                      <li>
                        <b>Flexibilidade de Pagamento:</b> Você pode parcelar
                        seus boletos em até 12x no cartão de crédito, oferecendo
                        mais controle sobre suas finanças.{" "}
                      </li>
                      <li>
                        <b>Sem Saldo na Conta:</b> Não é necessário ter saldo
                        disponível na conta, já que o pagamento é realizado pelo
                        cartão de crédito.
                      </li>
                      <li>
                        <b>Agilidade e Simplicidade:</b> Todas as operações são
                        realizadas com tecnologia segura, garantindo a proteção
                        dos seus dados.
                      </li>
                      <li>
                        <b>Segurança nas Transações:</b> O processo é rápido e
                        fácil. Em poucos minutos, você consegue simular e
                        parcelar suas contas diretamente pelo site.
                      </li>
                      <li>
                        <b>Organização Financeira:</b> Parcelar seus boletos ou
                        Pix ajuda a manter suas contas em dia, sem comprometer
                        todo o seu orçamento de uma vez.
                      </li>
                      <li>
                        <b>Acesso a Ofertas Exclusivas:</b> A{" "}
                        <span className="quita">Quita Boletos</span> oferece
                        cupons de desconto para facilitar ainda mais suas
                        operações.{" "}
                      </li>
                      <li>
                        <b>Praticidade no Atendimento:</b> Se precisar de ajuda,
                        a equipe está sempre disponível para tirar dúvidas e
                        ajudar no processo de parcelamento.
                      </li>
                    </ul>
                  </li>
                </ul>
              </Grid>
              <Grid item size={12}>
                <Typography sx={{ fontSize: 20 }}>
                  Parcelar suas contas é mais fácil do que você imagina! Clique
                  no botão abaixo para simular e utilize o cupom <b>QUITA10</b>{" "}
                  para parcelar com 10% de desconto.
                </Typography>
              </Grid>

              <Grid item size={12} align="center">
                <BotaoSimular />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
