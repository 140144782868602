import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function DicasPraticasParaEvitarDividasEManterOEquilibrioFinanceiro() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_DICAS_PRATICAS_PARA_EVITAR_DIVIDAS_E_MANTER_O_EQUILIBRIO_FINANCEIRO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);
  return (
    <Container className="sessao-post">
      <Grid
        item
        size={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
          <Grid
            className="logo-blog"
            size={12}
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}>
            <img
              className="logo-blog-img"
              src="/site/blog/blog_dicas_praticas_para_evitar_dividas_e_manter_o_equilibrio_financeiro.png"
              alt="logo-blog"
            />
          </Grid>

          <Grid item size={12}>
            <Typography variant="h1" sx={{ mt: 2, p: 1, textAlign: "center" }}>
              Organize suas finanças: dicas práticas para evitar dívidas e
              manter o equilíbrio financeiro
            </Typography>
          </Grid>

          <Grid item size={12}>
            <Typography sx={{ fontSize: 22, fontStyle: "italic" }}>
              Manter as finanças organizadas é essencial para evitar dívidas e
              alcançar o equilíbrio financeiro. Com algumas mudanças simples no
              dia a dia, você pode tomar o controle do seu dinheiro e garantir
              mais tranquilidade para o futuro. Confira neste artigo dicas
              práticas para transformar sua relação com o dinheiro.
            </Typography>
          </Grid>
          <Grid item container spacing={2} className="sessao">
            <ol className="list">
              <li>
                <strong>Faça um diagnóstico financeiro</strong> <br />O primeiro
                passo para organizar as finanças é entender sua situação atual.
                Liste todas as suas fontes de renda e despesas mensais,
                incluindo gastos fixos, como aluguel e contas, e variáveis, como
                lazer e compras. Assim, você identifica os pontos de melhoria e
                os gastos que podem ser reduzidos.
              </li>
              <li>
                <strong>Crie um orçamento mensal</strong> <br /> Um orçamento
                realista é a base para evitar dívidas. Separe suas despesas em
                categorias, como necessidades, desejos e poupança ou
                investimento.
              </li>
              <li>
                <strong>Monte uma reserva de emergência</strong> <br /> Ter uma
                reserva de emergência é fundamental para lidar com imprevistos
                sem recorrer a empréstimos ou cheque especial. Guarde
                mensalmente uma parte da sua renda.
              </li>
              <li>
                <strong>Controle os pequenos gastos</strong> <br /> Pequenos
                gastos diários, como café, lanches ou assinaturas, podem
                acumular um impacto significativo no seu orçamento. Analise suas
                prioridades e elimine despesas que não agregam valor ao seu dia
                a dia.
              </li>
              <li>
                <strong>Renegocie dívidas</strong> <br />
                Se você já está endividado, renegociar é uma das melhores formas
                de recuperar o controle financeiro. Muitas empresas oferecem
                condições especiais para quem deseja quitar débitos.
              </li>
              <li>
                <strong>Invista em educação financeira</strong> <br />A educação
                financeira é um investimento essencial para o futuro. Leia
                livros, participe de cursos ou consuma conteúdo online sobre
                finanças pessoais. Com mais conhecimento, você tomará decisões
                mais acertadas e evitará erros comuns.
              </li>
              <li>
                <strong>Comemore suas conquistas financeiras</strong> <br />
                Pagar uma dívida, economizar ou manter as contas em dia são
                grandes feitos. Reconheça essas vitórias e use-as como motivação
                para continuar organizando suas finanças.
              </li>
            </ol>

            <Grid item size={12}>
              <Typography sx={{ fontSize: 22 }}>
                Organizar suas finanças é a chave para evitar dívidas e alcançar
                o equilíbrio financeiro. Coloque em prática as dicas deste
                artigo e veja como pequenas mudanças podem gerar grandes
                resultados. Ao cuidar do seu dinheiro, você conquista mais
                liberdade e segurança para o futuro.
              </Typography>
            </Grid>

            <Grid item size={12}>
              <Typography sx={{ fontSize: 22 }}>
                Gostou das dicas? Continue acompanhando nosso blog para aprender
                mais sobre como cuidar das suas finanças!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
